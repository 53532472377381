import React from 'react';

import ProfilePic from '../styles/img/profile-pic.jpg';

export default function Header () {
    return (
        <div class="header">
            <div class="profile-picture">
                <div className="profile-picture__picture-frame">
                    <img src={ProfilePic} className="profile-picture__picture"/>
                </div>
            </div>

            <div>
                <h3>DAVID LAM</h3>
                <p>SOFTWARE DEVELOPER | TEST ENGINEER</p>
                <p>PHONE: 81831697 | EMAIL: davidlam.yc@gmail.com</p>
                <br />
                <p>Java | Javascript | Spring | React | Express</p>
                <p>Selenium | RestAssured | AWS | Jenkins</p>
            </div>
        </div>
    )
};
