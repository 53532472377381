import React from 'react';

export default function () {
    return (
        <section class="about">
            <h1>About</h1>

            <p>Hello there, I'm David. I spend most of my time at work and at play writing code in Java and Javascript.</p>

            <p>Professionally, I have worked in MNC and start-up environments as a Software Developer in Test. I've build automation testing frameworks for UI and API testing, and CI/CD pipelines to bake quality into development workflows.</p>

            <p>In my own time, I enjoy playing with new technologies. I currently spend (too much) time with React and Spring Boot.</p>
        </section>
    )
};