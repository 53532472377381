import React from 'react';

import TrelloPic from '../styles/img/trello-clone.png';
import OutdoorsPic from '../styles/img/outdoors-pic.png';
import StudentAppPic from '../styles/img/studentapp-pic.png';

export default function () {
    const goToSite = (url) => {
        window.open(url);
    }

    return (
        <section class="projects">
            <h1>Projects</h1>
            <p>Click on the card to go to the website.</p>
            <div class="row">
                <div class="col-1-of-3">
                    <div class="projects__card" onClick={() => goToSite("https://frozen-beyond-73366.herokuapp.com/")}>
                        <h3>Trello clone</h3>
                            <a href="https://frozen-beyond-73366.herokuapp.com/">
                                <img src={TrelloPic} />
                            </a>
                            <p>A task tracking app demostrating CRUD functionality and JWT-based authentication.</p>
                            <ul>
                                <p class="projects__built-with">Build with:</p>
                                <li>
                                    React
                                </li>
                                <hr />
                                <li>
                                    Redux
                                </li>
                                <hr />
                                <li>
                                    Express
                                </li>
                                <hr />
                                <li>
                                    MongoDB
                                </li>
                            </ul>
                    </div>
                </div>
                <div class="col-1-of-3">
                    <div class="projects__card" onClick={() => goToSite("http://outdoors-david.s3-website-ap-southeast-1.amazonaws.com/")}>
                    <h3>Mock Landing Page</h3>
                        <a href="http://outdoors-david.s3-website-ap-southeast-1.amazonaws.com/">
                            <img src={OutdoorsPic} />
                        </a>
                        <p>A landing page made for a fictional travel agency. Build to hone my Sass chops.</p>
                        <ul>
                            <p>Build with:</p>
                            <li>
                                Sass
                            </li>
                            <hr />
                            <li>
                                CSS Animations
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-1-of-3">
                    <div class="projects__card" onClick={() => goToSite("http://studentapp-env.eba-jc7vneir.ap-southeast-1.elasticbeanstalk.com/")}>
                        <h3>Student Register</h3>
                        <a href="http://studentapp-env.eba-jc7vneir.ap-southeast-1.elasticbeanstalk.com/">
                            <img src={StudentAppPic} />
                        </a>
                        <p>A simple student register build as a full stack application.</p>
                        <ul>
                            <p>Build with:</p>
                            <li>
                                React
                            </li>
                            <hr />
                            <li>
                                Spring Boot
                            </li>
                            <hr />
                            <li>
                                AWS S3
                            </li>
                            <hr />
                            <li>
                                AWS Elastic Beanstalk
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
    </section>
    )
};